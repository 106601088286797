import React from 'react';
import Layout from '../components/Layout';
import Component from '../components/TermsOfService';

function TermsOfService(props) {
  return (
    <Layout>
      <Component {...props} />
    </Layout>
  );
}

export default TermsOfService;
